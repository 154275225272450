body {
  background: #1b1b1b;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.app {
  /* max-width: 80%; */
  margin: 32px;
}

#drop_zone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 5px dashed #c8c8c850;
  width: 256px;
  height: 256px;
  margin: 10px;
  font-size: large;
}

#viewport {
  border: 2px solid #00000096;
  box-shadow: 3px 3px 11px #00000040;
  overflow: auto;
  background: #131313;
  display: grid;
  position: relative;
}

/*  scrollbar */
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #adadad78 14%, #63636381 64%);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(13deg, #d4d4d480 14%, #a8a8a881 64%);
}
::-webkit-scrollbar-track {
  background: #68686865;
  border-radius: 10px;
}

.button {
  width: 180px;
  text-align: center;
}

.viewportText {
  color: #ffffff24;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 45%;
  font-size: 48px;
}

.spin {
  animation: spin linear 3s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
